import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "../../components/Header/store";

import { redirectToExternalResource } from "../../helpers";

import "./index.css";

type PropsType = {
    title: string;
    description: string;
    link: string;
    img: string;
    id: string;
    consult: boolean;
};

export function PortalCard({
    consult,
    id,
    title,
    description,
    link,
    img,
}: PropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    return (
        <React.Fragment>
            <div
                style={{
                    backgroundColor: mainColor,
                    pointerEvents: !consult ? "none" : "auto",
                    opacity: !consult ? "0.4" : "",
                }}
                className="card__clz"
                onClick={() => {
                    redirectToExternalResource(link);
                }}
            >
                <div className="card-header__clz">
                    <img src={img} alt="rover" />
                </div>
                <div className="card-body__clz">
                    <h1 className="home-page-card-title__clz">{t(title)}</h1>
                    <span className="home-page-card-description__clz">
                        {t(description)}
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
}
