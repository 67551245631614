import React from "react";
import { useTranslation } from "react-i18next";

import NoDataSvgIcon from "@components/common/SvgIcons/NoDataSvgIcon";

export default function NotAuthorizationPage() {
    const { t } = useTranslation();
    return (
        <div style={{ marginBottom: "10%" }}>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ marginTop: "5%" }}
            >
                <NoDataSvgIcon />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
                <h5>{t("Unauthorized access")}</h5>
            </div>
        </div>
    );
}
