import * as actions from "./actionTypes";

const initialState: LoginReducerInitStateType = {
    error: false,
    loading: false,
    user: null,
};

export default function login(
    state = initialState,
    action: LoginReducerActionType
) {
    switch (action.type) {
        case actions.LOGIN_USER:
            state = {
                ...state,
                loading: true,
            };
            break;
        case actions.LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                user: action.payload,
            };
            break;
        case actions.LOGOUT_USER:
            state = { ...state };
            break;
        case actions.LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case actions.API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}
