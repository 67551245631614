import React from "react";
import { Helmet } from "react-helmet";

type PageTitleProposType = {
    title: string;
};

export default function PageTitle({ title }: PageTitleProposType) {
    return (
        <Helmet>
            <title>ETK | {title}</title>
        </Helmet>
    );
}
