import axios from "axios";
import fakeBackend from "./AuthType/fakeBackend";

fakeBackend();

// Gets the logged in user data from local session
const getLoggedInUser = (): UserType | null => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
};

//is user is logged in
const isUserAuthenticated = (): boolean => {
    return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299) {
                return response.data;
            }
            throw response.data;
        })
        .catch((err) => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message =
                            "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message =
                            "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
const postFakeLogin = (
    url: string,
    data: {
        email: string;
        password: string;
    }
): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status === 400 || response.status === 500) {
                throw response.data;
            }
            return response.data;
        })
        .catch((err) => {
            throw err[1];
        });
};

// postForgetPwd
const postFakeForgetPwd = (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            return response.data;
        })
        .catch((err) => {
            throw err[1];
        });
};

// Login Method
const postJwtProfile = (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data, {
            headers: {
                Authorization:
                    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkbWluIiwiYWRtaW4iOnRydWUsImp0aSI6ImQ2MTEwYzAxLWMwYjUtNDUzNy1iNDZhLTI0NTk5Mjc2YjY1NiIsImlhdCI6MTU5MjU2MDk2MCwiZXhwIjoxNTkyNTY0NjE5fQ.QgFSQtFaK_Ktauadttq1Is7f9w0SUtKcL8xCmkAvGLw",
            },
        })
        .then((response) => {
            if (response.status === 400 || response.status === 500) {
                throw response.data;
            }
            return response.data;
        })
        .catch((err) => {
            throw err[1];
        });
};

const postFakeProfile = (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status === 400 || response.status === 500) {
                throw response.data;
            }
            return response.data;
        })
        .catch((err) => {
            throw err[1];
        });
};

// Register Method
const postJwtRegister = (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299) {
                return response.data;
            }
            throw response.data;
        })
        .catch((err) => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message =
                            "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message =
                            "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
const postJwtLogin = async (url: string, data: any): Promise<any> => {
    return fetch(url, {
        headers: {
            "accept-language": "en-US,en;q=0.9",
            "content-type": "application/json",
            "x-requested-with": "XMLHttpRequest",
        },
        referrer: `${url}`,
        referrerPolicy: "strict-origin-when-cross-origin",
        body: JSON.stringify(data),
        method: "POST",
        mode: "cors",
        credentials: "omit",
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 400 || response.status === 500) {
                throw Error("Error send request");
            }

            return response;
        })
        .catch((err) => {
            throw err;
        });
};

const getFavorites = async (url: string): Promise<any> => {
    let token = localStorage.getItem("jwt");
    return fetch(url, {
        headers: {
            "accept-language": "en-US,en;q=0.9",
            "content-type": "application/json",
            "x-requested-with": "XMLHttpRequest",
            "x-authorization": `Bearer ${token}`,
        },
        referrer: url,
        referrerPolicy: "strict-origin-when-cross-origin",
        method: "GET",
        mode: "cors",
        credentials: "omit",
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 400 || response.status === 500) {
                throw Error("Error send request");
            }

            return response;
        })
        .catch((err) => {
            throw err;
        });
};

// postForgetPwd
const postJwtForgetPwd = async (url: string, data: any): Promise<any> => {
    return axios
        .post(url, data)
        .then((response) => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            return response.data;
        })
        .catch((err) => {
            throw err[1];
        });
};

function isTokenExpired(jwtTokenExpireDate: number): boolean {
    try {
        if (jwtTokenExpireDate > Date.now() / 1000) {
            return false;
        }

        // if the jwt token is expired then the `localStorage` should be cleared
        localStorage.clear();
        return true;
    } catch (e: unknown) {
        localStorage.clear();
        return true;
    }
}

export {
    getLoggedInUser,
    isUserAuthenticated,
    postFakeRegister,
    postFakeLogin,
    postFakeProfile,
    postFakeForgetPwd,
    postJwtRegister,
    postJwtLogin,
    postJwtForgetPwd,
    postJwtProfile,
    isTokenExpired,
    getFavorites,
};
