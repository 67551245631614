import {
    PutEffect,
    takeEvery,
    fork,
    put,
    all,
    call,
    CallEffect,
} from "redux-saga/effects";

import { AnyAction } from "redux";
import jwt_decode from "jwt-decode";
import { push, replace } from "connected-react-router";

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

import { loginSuccess, apiError } from "./actions";
//import { changeDate } from "@store/statistics/actions";

import { postJwtLogin, postFakeLogin } from "../../../helpers/backend_helper";

type LoginResponseType =
    | {
          error: true;
          message: string;
      }
    | {
          error: false;
          data: string;
      };

function* loginUser({
    payload: { user },
}: AnyAction): Generator<
    CallEffect<any> | PutEffect<AnyAction>,
    void,
    unknown
> {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const apiUrl: string = `${process.env
                .REACT_APP_API_URL!}/auth/login`;

            // @see https://github.com/redux-saga/redux-saga/issues/2015#issuecomment-586476693
            const response: LoginResponseType = (yield call(
                postJwtLogin,
                apiUrl,
                {
                    email: user.email,
                    password: user.password,
                }
            )) as LoginResponseType;

            if (response.error) {
                yield put(apiError(true));
            } else {
                let data: any = response.data;
                let accessToken: any = data.accessToken;
                let Token: any = data.Token;
                let decodedJwt: any = jwt_decode(accessToken);
                let user: UserType = {
                    id: decodedJwt.id,
                    firstname: decodedJwt.firstname,
                    lastname: decodedJwt.lastname,
                    address: decodedJwt.address,
                    consult: JSON.parse(decodedJwt.consult),
                    email: decodedJwt.email,
                    exp_client: decodedJwt.exp_client,
                    group_id: decodedJwt.group_id,
                    username: decodedJwt.login,
                    phone: decodedJwt.phone,
                };

                localStorage.setItem("authUser", JSON.stringify(user));
                localStorage.setItem("jwt", Token);
            }
        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            const response: any = yield call(
                postFakeLogin,
                "/post-fake-login",
                {
                    email: user.email,
                    password: user.password,
                }
            );

            delete response.password;

            localStorage.setItem("authUser", JSON.stringify(response));
            localStorage.setItem("jwt", btoa(JSON.stringify(response)));
            yield put(loginSuccess(response));
        }
        yield put(replace("/my-orders"));
    } catch (error) {
        yield put(apiError(true));
    }
}

function* logoutUser() {
    try {
        localStorage.clear();

        yield put(apiError(false));

        yield put(push("/login"));
    } catch (error) {
        yield put(apiError(true));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
