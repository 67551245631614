import crypto from "crypto";
import i18n from "i18next";

import { EMAIL_VALIDATION_REGEX } from "../constants";

export function redirectToExternalResource(url: string) {
    window.open(url);
}

export function measurePasswordStrengthAsNumber(password: string): number {
    let passwordStrengthMeter: boolean =
        password.match(/[0-9]/g) !== null &&
        password.match(/[a-z]/g) !== null &&
        password.match(/[A-Z]/g) !== null &&
        password.match(/[^A-Za-z0-9]/g) !== null;

    const EMPTY_PASSWORD = 0;
    const WEAK_PASSWORD = 1;
    const FAIR_PASSWORD = 2;
    const GOOD_PASSWORD = 3;
    const STRONG_PASSWORD = 4;

    if (password.length === 0) {
        return EMPTY_PASSWORD;
    } else if (password.length === 8 && passwordStrengthMeter) {
        return FAIR_PASSWORD;
    } else if (
        password.length > 8 &&
        password.length < 12 &&
        passwordStrengthMeter
    ) {
        return GOOD_PASSWORD;
    } else if (password.length >= 12 && passwordStrengthMeter) {
        return STRONG_PASSWORD;
    } else {
        return WEAK_PASSWORD;
    }
}

export function getPasswordStrength(result: number): {
    strength: string;
    color: string;
} {
    switch (result) {
        case 1:
            return { strength: i18n.t("Weak"), color: "danger" };
        case 2:
            return { strength: i18n.t("Fair"), color: "warning" };
        case 3:
            return { strength: i18n.t("Good"), color: "success" };
        case 4:
            return { strength: i18n.t("Strong"), color: "success" };
        default:
            return { strength: i18n.t("Weak"), color: "danger" };
    }
}

export function isEmailValid(email: string) {
    return EMAIL_VALIDATION_REGEX.test(email);
}

export async function logError(Source: any, data: any) {
    let fs = require("fs");
    let dir = "./";
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir);
    }
    let madatetrace = new Date()
        .toISOString()
        .replace("T", " ")
        .substring(0, 19);
    let JourTrace = madatetrace.substring(0, 10);
    let heuretrace = madatetrace.substring(10, 25);
    let Reptrace = dir + "\\" + JourTrace.toString() + " LogServer.txt";
    let msg =
        "************************( " +
        Source +
        " )********************************" +
        "\r" +
        JourTrace +
        " " +
        heuretrace +
        "\r" +
        data.toString() +
        "\r" +
        "----------------------------------------------------------------------------------------" +
        "\r";
    fs.appendFile(Reptrace, msg, function (erreur: any) {
        if (erreur) return console.log("erreur");
    });
}

export function decryptWord(wordToDecrypt: string): string {
    let decryptedWord = wordToDecrypt;
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);
    const buff = Buffer.from(decryptedWord, "base64");
    decryptedWord = buff.toString("utf8");
    const decryptor = crypto.createDecipheriv(
        String("AES256"),
        // String(`${process.env.REACT_APP_Algorithm}`),
        key,
        iv
    );

    const decryptedData =
        decryptor.update(decryptedWord, "base64", "utf8") +
        decryptor.final("utf8");
    return decryptedData;
}

export function cryptWord(wordToCrypt: string): string {
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);

    const encryptor = crypto.createCipheriv(
        // String(`${process.env.REACT_APP_Algorithm}`),
        String("AES256"),
        key,
        iv
    );
    const aesEncrypted =
        encryptor.update(wordToCrypt, "utf8", "base64") +
        encryptor.final("base64");
    return Buffer.from(aesEncrypted).toString("base64");
}
