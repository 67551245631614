import React, { useEffect, useState } from "react";
import {
    TokenRequest,
    BaseTokenRequestHandler,
    GRANT_TYPE_AUTHORIZATION_CODE,
    AuthorizationServiceConfiguration,
    RedirectRequestHandler,
    AuthorizationNotifier,
    FetchRequestor,
    LocalStorageBackend,
    DefaultCrypto,
    StringMap,
} from "@openid/appauth";
import jwt_decode from "jwt-decode";

import { NoHashQueryStringUtils } from "./noHashQueryStringUtils";

export const Callback = (props: any) => {
    const [error, setError] = useState<string>();
    const [code, setCode] = useState<string>();

    useEffect(
        function () {
            const tokenHandler = new BaseTokenRequestHandler(
                new FetchRequestor()
            );
            const authorizationHandler = new RedirectRequestHandler(
                new LocalStorageBackend(),
                new NoHashQueryStringUtils(),
                window.location,
                new DefaultCrypto()
            );
            const notifier = new AuthorizationNotifier();
            authorizationHandler.setAuthorizationNotifier(notifier);
            notifier.setAuthorizationListener((request, response, error) => {
                if (response) {
                    let extras: StringMap = {
                        code_verifier: "",
                        client_secret: "",
                    };

                    if (request && request.internal) {
                        extras.code_verifier = request.internal.code_verifier;
                        extras.client_secret = process.env
                            .REACT_APP_CLIENT_SECRET as string;
                    }

                    const tokenRequest = new TokenRequest({
                        client_id: process.env.REACT_APP_CLIENT_ID as string,
                        redirect_uri: process.env
                            .REACT_APP_REDIRECT_URL as string,
                        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                        code: response.code,
                        refresh_token: undefined,
                        extras,
                    });

                    AuthorizationServiceConfiguration.fetchFromIssuer(
                        process.env.REACT_APP_OPSERVER as string,
                        new FetchRequestor()
                    )
                        .then((oResponse) => {
                            const configuration = oResponse;
                            return tokenHandler.performTokenRequest(
                                configuration,
                                tokenRequest
                            );
                        })
                        .then((oResponse) => {
                            localStorage.setItem(
                                "access_token",
                                oResponse.accessToken
                            );
                            const TokenIdTemp: any = oResponse.idToken;
                            localStorage.setItem(
                                "access_token_id",
                                TokenIdTemp
                            );
                            let dataUserConnected: any =
                                jwt_decode(TokenIdTemp);
                            const UidEntitieUser: string =
                                dataUserConnected.sub.uidEntitie;
                            localStorage.setItem(
                                "Uid_Entitie_User",
                                UidEntitieUser
                            );
                            //props.history.push("/Gestioncomptes");
                            props.history.push("/home");
                        })
                        .catch((oError) => {
                            setError(oError);
                        });
                }
            });

            const params = new URLSearchParams(props.location.search);
            setCode(params.get("code")!);

            if (!code) {
                setError("Unable to get authorization code");
                return;
            }
            authorizationHandler.completeAuthorizationRequestIfPossible();
        },
        [code, props]
    );

    return (
        <div className="container-fluid" style={{ marginTop: "10px" }}>
            <div className="card">
                {code ? (
                    <div className="card-body">
                        <h5 className="card-title">Loading is progress...</h5>
                    </div>
                ) : (
                    <div className="card-body bg-danger">
                        <div className="card-body">
                            <p className="card-text">{error}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
