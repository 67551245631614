import { proxy, subscribe } from "valtio";

type StoreType = {
    mainColor: string;
    secondaryColor: string;
};

const defaultStateStore = {
    mainColor: "#171616",
    secondaryColor: "#c4bfbe",
};

export const store = proxy<StoreType>(defaultStateStore);

subscribe(store, () => {
    window.localStorage.setItem("store", JSON.stringify(store));
});

export function setMainColor(color: any) {
    store.mainColor = color;
}

export function setSecondaryColor(color: any) {
    store.secondaryColor = color;
}
