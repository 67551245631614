import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import store from "./store";

import "./i18n";
import * as serviceWorker from "./serviceWorker";

import App from "./App";

function Main() {
    let persistor = persistStore(store);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
            <ToastContainer />
        </Provider>
    );
}

ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
